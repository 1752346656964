import type { AppProps } from 'next/app';
import type { EmotionCache } from '@emotion/cache';
import type { NextPage } from 'next';
import { FC, useEffect } from 'react';
import Head from 'next/head';
import { CacheProvider } from '@emotion/react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Toaster } from 'react-hot-toast';
import { Provider as ReduxProvider } from 'react-redux';
import { FlareErrorBoundary } from '@flareapp/flare-react';
import * as Sentry from '@sentry/nextjs';
import { UserbackProvider } from '@userback/react';
import { Analytics } from '@vercel/analytics/react';
import { PostHogProvider } from 'posthog-js/react';
import { SplashScreen } from '@/components/_shared/layouts/splash-screen';
import { AuthConsumer, AuthProvider } from '@/lib/contexts/auth-context';
import { DarkModeConsumer, DarkModeProvider } from '@/lib/contexts/dark-mode-context';
import { store } from '@/lib/state/store';
import { createTheme } from '@/lib/theme';
import { createEmotionCache } from '@/lib/utils/create-emotion-cache';
import ErrorBoundary from '@/components/_shared/error-boundary/Error';
import Maintenance from '@/pages/maintenance';
import { gtmConfig } from '@/lib/gtm/config';
import { gtm } from '@/lib/gtm/gtm';
import { initializePostHog, usePostHogPageView } from '@/lib/utils/posthog';
import { setupNProgress } from '@/lib/utils/nprogress';
import { setupAxios } from '@/lib/utils/axios-config';
import { flare } from '@flareapp/flare-client';
import '@/lib/styles/global.css';
import '@/lib/i18n/i18n';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { useRouter } from 'next/router';
type EnhancedAppProps = AppProps & {
  Component: NextPage;
  emotionCache: EmotionCache;
};
const clientSideEmotionCache = createEmotionCache();
flare.light(process.env.NEXT_PUBLIC_FLARE);
setupNProgress();
setupAxios();
const App: FC<EnhancedAppProps> = props => {
  const {
    Component,
    emotionCache = clientSideEmotionCache,
    pageProps
  } = props;
  const getLayout = Component.getLayout ?? (page => page);
  const router = useRouter();
  usePostHogPageView(router);
  useEffect(() => {
    gtm.initialize(gtmConfig);
  }, []);

  // TODO: add maintenance mode when backend is building
  const maintenance: boolean | undefined = false;
  if (maintenance) {
    return <Maintenance />;
  }
  return <FlareErrorBoundary data-sentry-element="FlareErrorBoundary" data-sentry-component="App" data-sentry-source-file="_app.tsx">
      <Sentry.ErrorBoundary fallback={({
      error
    }) => <ErrorBoundary error={error} />} data-sentry-element="unknown" data-sentry-source-file="_app.tsx">
        <CacheProvider value={emotionCache} data-sentry-element="CacheProvider" data-sentry-source-file="_app.tsx">
          <Head data-sentry-element="Head" data-sentry-source-file="_app.tsx">
            <title>RipeMetrics</title>
            <meta name="viewport" content="initial-scale=1, width=device-width" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
          </Head>

          <ReduxProvider store={store} data-sentry-element="ReduxProvider" data-sentry-source-file="_app.tsx">
            <LocalizationProvider dateAdapter={AdapterDateFns} data-sentry-element="LocalizationProvider" data-sentry-source-file="_app.tsx">
              <AuthProvider data-sentry-element="AuthProvider" data-sentry-source-file="_app.tsx">
                <AuthConsumer data-sentry-element="AuthConsumer" data-sentry-source-file="_app.tsx">
                  {auth => <DarkModeProvider>
                      <DarkModeConsumer>
                        {({
                      darkMode
                    }) => <ThemeProvider theme={createTheme({
                      responsiveFontSizes: darkMode.responsiveFontSizes,
                      mode: darkMode.theme
                    })}>
                            <PostHogProvider client={initializePostHog()}>
                              <CssBaseline />
                              <Toaster position="top-right" reverseOrder={true} />

                              {!auth.isInitialized ? <SplashScreen /> : <UserbackProvider token={process.env.NEXT_PUBLIC_USERBACK_TOKEN as string}>
                                  {getLayout(<ErrorBoundary>
                                      <Component {...pageProps} />
                                    </ErrorBoundary>)}
                                </UserbackProvider>}
                            </PostHogProvider>
                          </ThemeProvider>}
                      </DarkModeConsumer>
                    </DarkModeProvider>}
                </AuthConsumer>
              </AuthProvider>
            </LocalizationProvider>
          </ReduxProvider>
          <Analytics data-sentry-element="Analytics" data-sentry-source-file="_app.tsx" />
        </CacheProvider>
      </Sentry.ErrorBoundary>
    </FlareErrorBoundary>;
};
export default App;