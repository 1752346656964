import { authApi } from '@/lib/api/auth/auth-api';
import { identity, setTwilioToken, updateCallCenterName } from '@/lib/state/slices/call-center-slice';
import { updateUserPermissions } from '@/lib/state/slices/user-permissions-slice';
import { persistor } from '@/lib/state/store';
import axios from 'axios';
import { useRouter } from 'next/router';
import posthog from 'posthog-js';
import PropTypes from 'prop-types';
import type { FC, ReactNode } from 'react';
import { createContext, useEffect, useReducer } from 'react';
import { useAppDispatch } from '../state/hooks';
import { logOut, setCredentials } from '../state/slices/auth-slice';
import type { User } from '../types/user';
declare global {
  interface Window {
    Echo: any;
  }
}
const baseUrl = `${process.env.NEXT_PUBLIC_API_BASE_URL}/api`;
const sanctumBaseUrl = process.env.NEXT_PUBLIC_API_BASE_URL;

// Defining the type State of the authentication.
interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: User | null;
}

// Defining the type of the context.
export interface AuthContextValue extends State {
  platform: 'JWT';
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  register: (email: string, name: string, password: string) => Promise<void>;
}

// Defining the props types that the AuthProvider component will take
interface AuthProviderProps {
  children: ReactNode;
}

// Defining the action types that will be dispatched
enum ActionType {
  INITIALIZE = 'INITIALIZE',
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  REGISTER = 'REGISTER',
}

/**
 * InitializeAction is an object with a type property that is a member of the ActionType enum and a
 * payload property that is an object with an isAuthenticated property that is a boolean and a user
 * property that is a User or null
 * @property type - ActionType.INITIALIZE;
 * @property payload - { isAuthenticated: boolean, user: User | null }
 */
type InitializeAction = {
  type: ActionType.INITIALIZE;
  payload: {
    isAuthenticated: boolean;
    user: User | null;
  };
};

/**
 * LoginAction is an object with a type property that is a string equal to 'LOGIN' and a payload
 * property that is an object with a user property that is a User.
 * @property type - ActionType.LOGIN;
 * @property payload - { user: User }
 */
type LoginAction = {
  type: ActionType.LOGIN;
  payload: {
    user: User;
  };
};

/**
 * LogoutAction is an object with a type property that is a string with the value of 'LOGOUT'
 * @property type - ActionType.LOGIN;
 */
type LogoutAction = {
  type: ActionType.LOGOUT;
};

/**
 * RegisterAction is an object with a type property that is a string literal type with the value
 * 'REGISTER' and a payload property that is an object with a user property that is a User type.
 * @property type - ActionType.REGISTER;
 * @property payload - {
 */
type RegisterAction = {
  type: ActionType.REGISTER;
  payload: {
    user: User;
  };
};
type Action = InitializeAction | LoginAction | LogoutAction | RegisterAction;
type Handler = (state: State, action: any) => State;

// Defining the initial state of authentication.
const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

// A map of the action type to the handler function.
// INIITIALIZE, LOGIN, LOGOUT, REGISTER take a state and an action and returns a new state.
const handlers: Record<ActionType, Handler> = {
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const {
      isAuthenticated,
      user
    } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state: State, action: LoginAction): State => {
    const {
      user
    } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state: State): State => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state: State, action: RegisterAction): State => {
    const {
      user
    } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      user
    };
  }
};

/**
 * If the action type is a key in the handlers object, then call the function that's the value of that
 * key, passing in the state and action, otherwise return the state.
 * @param {State} state - State - the current state of the application
 * @param {Action} action - Action - The action object that was dispatched.
 */
const reducer = (state: State, action: Action): State => handlers[action.type] ? handlers[action.type](state, action) : state;

// AuthContext is a context object with the initial state and the functions that will be used to update the state.
export const AuthContext = createContext<AuthContextValue>({
  ...initialState,
  platform: 'JWT',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve()
});
export const AuthProvider: FC<React.PropsWithChildren<AuthProviderProps>> = props => {
  const {
    children
  } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const dispatchRedux = useAppDispatch();
  const router = useRouter();

  /* Checking to see if there is an accessToken in localStorage. If there is, it is calling the me
    function in authApi.ts. If there is not, it is setting the state to isAuthenticated: false and
    user: null. */
  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        // get token from local storage
        axios.defaults.headers.common['Authorization'] = `Bearer ${window.localStorage.getItem('accessToken')} `;
        //check token validity
        const isValid = (await axios.post(`${baseUrl}/check`)) as any;
        if (isValid.data.authenticated == true) {
          // checking to see if valid nothing else runs if not valid
          // if it wasn't valid user was throwing unnecessary errors in logs
          const user = (await axios.get(`${baseUrl}/user`)) as any;
          const userToDispatch = user.data.user as User;
          const loginObject = {
            token: 'none',
            user: userToDispatch,
            assignments: user.assignments
          };
          dispatchRedux(setCredentials(loginObject));
          dispatchRedux(setTwilioToken(userToDispatch.permissions.token));
          dispatchRedux(identity(userToDispatch.permissions.identity));
          dispatchRedux(updateCallCenterName(userToDispatch.call_center_name));

          // Identify user in PostHog
          posthog.identify(userToDispatch.id, {
            email: userToDispatch.email,
            name: userToDispatch.name
            // Add any other relevant user properties
          });
          dispatch({
            type: ActionType.INITIALIZE,
            payload: {
              isAuthenticated: true,
              user: userToDispatch
            }
          });
          const userEntranceURL = userToDispatch?.permissions?.entrance_url;
          const expectedOnboardingEntranceURLs = ['dashboard/onboarding/step-one', 'dashboard/onboarding/step-two', 'dashboard/onboarding/verify-email', 'dashboard/onboarding/billing/contact-your-administrator', `${process.env.NEXT_PUBLIC_API_BASE_URL}/subscription-checkout`];
          if (router.pathname !== '/') {
            if (userToDispatch?.permissions?.billing_url) {
              try {
                window.location.href = userToDispatch.permissions.billing_url;
              } catch (error) {
                console.error(error);
              }
            } else {
              if (expectedOnboardingEntranceURLs.includes(userEntranceURL)) {
                if (router.pathname !== `/${userEntranceURL}`) {
                  if (userEntranceURL === `${process.env.NEXT_PUBLIC_API_BASE_URL}/subscription-checkout`) {
                    router.push(userEntranceURL);
                  } else {
                    router.push(`/${userEntranceURL}`);
                  }
                }
              }
            }
          }
        } else {
          window.localStorage.clear();

          // Reset PostHog user
          posthog.reset();
          dispatch({
            type: ActionType.INITIALIZE,
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: ActionType.INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };
    initialize();
  }, [dispatch, dispatchRedux, router]);

  /**
   * login function is declared with a type annotation. The type annotation says that the function takes
   * two parameters, an email and a password, and returns a promise that resolves to void.
   * @param {string} email - string, password: string
   * @param {string} password - string
   */
  type LoginFormData = {
    username: string;
    password: string;
  };
  const login = async (loginFormData: any): Promise<void> => {
    await axios.get(`${sanctumBaseUrl}/sanctum/csrf-cookie`);

    //calling login endpoint with loginFormData

    const res = await axios.post(`${baseUrl}/login`, loginFormData);
    //set token from res in local storage
    localStorage.setItem('accessToken', res.data.token);
    //apply token to axios headers

    //make check call with token to verify token is valid
    const userResponse = await axios.get(`${baseUrl}/user`);
    if (userResponse.data == false) {
      window.localStorage.clear();
      posthog.reset(); // Reset PostHog user on failed login
      dispatch({
        type: ActionType.INITIALIZE,
        payload: {
          isAuthenticated: false,
          user: null
        }
      });
    } else {
      const user = userResponse.data.user;
      const userToDispatch = user as User;

      // Identify user in PostHog
      posthog.identify(userToDispatch.id, {
        email: userToDispatch.email,
        name: userToDispatch.name
        // Add any other relevant user properties
      });
      const loginObject = {
        token: 'none',
        user: userToDispatch,
        assignments: user.assignments
      };
      //set user to the user slice of state
      dispatchRedux(updateUserPermissions(user?.permissions));
      dispatchRedux(setCredentials(loginObject));
      dispatchRedux(setTwilioToken(user.permissions.token));
      dispatchRedux(identity(user.permissions.identity));
      dispatchRedux(updateCallCenterName(user.call_center_name));
      if (user.permissions.billing_url) {
        try {
          window.location.href = user.permissions.billing_url;
        } catch (error) {
          console.error(error);
        }
      } else if (user.permissions.entrance_url) {
        router.push(`/${user.permissions.entrance_url}`).catch(console.error);
      }

      //next reroute to the entrance url

      /* Dispatching user payload to the LOGIN. */
      dispatch({
        type: ActionType.LOGIN,
        payload: {
          user: userToDispatch
        }
      });
    }
  };

  // The logout function removes the accessToken from localStorage and dispatches LOGOUT.
  const logout = async (): Promise<void> => {
    localStorage.removeItem('accessToken');
    window.localStorage.clear();

    // Reset PostHog user
    posthog.reset();
    dispatchRedux(logOut());
    persistor.purge();
    if (window.Echo && window.Echo.connector.pusher) {
      window.Echo.connector.pusher.disconnect();
    }
    if (window.Echo) {
      delete window.Echo;
    }

    // Clear local and session storage
    localStorage.clear();
    sessionStorage.clear();

    //clear all local storage and cookies
    //make axios call to logout
    await axios.post(`${baseUrl}/logout`);
    dispatch({
      type: ActionType.LOGOUT
    });
  };

  // Register is a function that takes in an email, name, and password, and returns a promise that
  const register = async (email: string, name: string, password: string): Promise<void> => {
    /* Calling the register function in authApi.ts and passing in an email, name, and password.
    Then it is calling the me function in authApi.ts and passing in the accessToken. */
    const accessToken = await authApi.register({
      email,
      name,
      password
    });
    const user = await authApi.me(accessToken);
    localStorage.setItem('accessToken', accessToken);

    // Dispatching the Register Action to the reducer.
    dispatch({
      type: ActionType.REGISTER,
      payload: {
        user
      }
    });
  };
  return /* Taking the state and adding the platform, login, logout, and register functions to it. */<AuthContext.Provider value={{
    ...state,
    platform: 'JWT',
    login,
    logout,
    register
  }} data-sentry-element="unknown" data-sentry-component="AuthProvider" data-sentry-source-file="auth-context.tsx">
      {children}
    </AuthContext.Provider>;
};

// A prop type checker. It is checking to see if the children prop is a node. If it is not, it will throw an error.
AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};
export const AuthConsumer = AuthContext.Consumer;