import { logOut } from '@/lib/state/slices/auth-slice';
import { persistor } from '@/lib/state/store';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import axios from 'axios';

export interface LoginRequest {
  username: string;
  password: string;
}

interface User {
  id: number;
  name: string;
  email: string;
  emailRaw: string;
}

export interface UserResponse {
  user: User;
  token: string;
}

type FetchResult = {
  data: unknown;
  error: { originalStatus: number };
};
export interface passwordResetRequest {
  email: string | null;
}

export interface passwordResetResponse {
  message: string;
}

export interface RegisterResponse {
  data?: UserResponse;
  error?: { message: string };
}

interface RegisterRequest {
  organization_name: string;
  first_name: string;
  last_name: string;
  name: string;
  mobile: string;
  email: string;
  agree_to_terms: boolean;
  password: string;
  password_confirmation: string;
}

const baseUrl = `${process.env.NEXT_PUBLIC_API_BASE_URL}/api`;

export const api = createApi({
  baseQuery: async (args, api, extraOptions) => {
    const baseQuery = fetchBaseQuery({
      baseUrl: baseUrl,
      credentials: 'include',
      prepareHeaders: (headers, { getState }) => {
        const token = window.localStorage.getItem('accessToken');
        if (token) {
          headers.set('Authorization', `Bearer ${token}`);
        }
        if (!headers.get('Content-Type')) {
          headers.set('Accept', 'application/json');
        }
        headers.set(
          'Access-Control-Allow-Origin',
          process.env.NEXT_PUBLIC_SITE_URL as string
        );
        return headers;
      }
    });

    const result = await baseQuery(args, api, extraOptions);
    const dispatch = api.dispatch;
    // If the server returns a 401 or 419 status code, the user's session has expired
    // this wrapper will log the user out and redirect them to the login page

    if (
      result.error &&
      (result.error.status === 401 || result.error.status === 419)
    ) {
      console.error('Session expired, logging out');

      // Inform the server that the user is logging out
      await axios.post(`${baseUrl}/logout`);

      // Dispatch the logout action to clear Redux state
      dispatch(logOut());

      // Disconnect any active Pusher connection
      if (window.Echo && window.Echo.connector.pusher) {
        window.Echo.connector.pusher.disconnect();
        delete window.Echo;
      }

      // Clear all local storage data
      window.localStorage.removeItem('accessToken');
      window.localStorage.clear();
      window.sessionStorage.clear();

      // Purge persisted Redux state
      persistor.purge();

      // Redirect the user to the home or login page
      window.location.href = '/authentication/login';
    }

    return result;
  },

  tagTypes: [
    'AiSetupsData',
    'BusinessIntelligenceOverviewData',
    'ProductPerformanceSummaryData',
    'ProductPerformancePerformanceData',
    'ProductPerformanceReconsiderData',
    'ShoppingTrendsSummaryData',
    'ShoppingTrendsTrendsData',
    'ShoppingTrendsEcommerceData',
    'CustomerBehaviorsSummaryData',
    'CustomerBehaviorsBehaviorsData',
    'CustomerBehaviorsRetentionData',
    'MultiLocationAnalysisData',
    'MultiLocationLocationsData',
    'CalendarItems',
    'CommunicationTheaterData',
    'CustomerProfileData',
    'CustomerFeedbackSummaryData',
    'CustomerFeedbackFeedbackData',
    'MarketingAppraisalSummaryData',
    'MarketingAppraisalAppraisalData',
    'MarketingAppraisalAudienceData',
    'EnhancedMarketingIntelligenceSummaryData',
    'EnhancedSegmentsAutomatedSegmentsData',
    'EnhancedMarketingGroupsCreateAGroupData',
    'EnhancedSegmentsCreateASegmentData',
    'EnhancedCampaignsAutomatedCampaignsData',
    'EnhancedCampaignsCreateACampaignData',
    'EnhancedCampaignsInitiatedCampaignsData',
    'EnhancedLandingPageData',
    'AllContactsMapsData',
    'AllContactsProfilesData',
    'AllContactUploadData',
    'EmployeeEngagementSummaryData',
    'EmployeeEngagementFeedbackData',
    'MonthlyReportsData',
    'StoreData',
    'StoreAlerts',
    'StoreUsers',
    'CampaignMetricsData',
    'ContactCommunicationPreferences',
    'DealsOfTheMonth',
    'Narratives',
    'Organizations',
    'OnboardingOrganizationData',
    'GroupedSuperInteractionsData',
    'SuperInteractionsOrdinalOptionsData',
    'ProspectManagementOpportunitiesData',
    'ProspectsManagementProspectsData',
    'ProspectManagementSummaryData',
    'smtp_data',
    'pop_data',
    'CommunicationVolumeSummaryData',
    'CommunicationVolumeVolumeData',
    'CommunicationVolumeContactsData',
    'CommunicationTheaterDataAutorespond',
    'AiRecommendationsStatCards',
    'AiRecommendationsBusinessTrendsSummary',
    'AiRecommendationsCustomerLifetimeValueSummary',
    'AiRecommendationsCustomerTrendsSummary',
    'AiRecommendationsRevenueData',
    'AiRecommendationsCustomerRetentionData',
    'AiRecommendationsCustomerGrowthData',
    'AiRecommendationsSummaryBubbleChartData',
    'AiRecommendationsCustomerTrendsStackedBarChartData',
    'AiChatBotTest',
    'AiRecommendationsCustomerTrendsAccordions'
  ],

  refetchOnMountOrArgChange: true,

  endpoints: (builder) => ({
    login: builder.mutation<FetchResult, LoginRequest>({
      query: (credentials) => ({
        url: '/login',
        method: 'POST',
        body: credentials
      })
    }),
    logout: builder.mutation<void, void>({
      query: () => ({
        url: '/logout',
        method: 'POST'
      })
    }),
    passwordReset: builder.mutation<
      passwordResetResponse,
      passwordResetRequest
    >({
      query: (email) => ({
        url: '/password_reset_request',
        method: 'POST',
        body: email
      })
    }),
    register: builder.mutation<RegisterResponse, RegisterRequest>({
      query: (credentials) => ({
        url: '/register',
        method: 'POST',
        body: credentials
      })
    }),
    protected: builder.mutation<{ message: string }, void>({
      query: () => 'protected'
    })
  })
});

export const {
  useLoginMutation,
  useProtectedMutation,
  usePasswordResetMutation,
  useRegisterMutation,
  useLogoutMutation
} = api;
